import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

const StyledMainContainer = styled.main`
  // ... existing styles

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    background-color: var(--light-navy);
    color: var(--lightest-slate);
    font-size: var(--fz-lg);
    font-weight: bold;
    text-align: left;
    padding: 1rem;
  }

  td {
    border-top: 1px solid var(--light-navy);
    padding: 1rem;
  }

  .service__title {
    color: var(--lightest-slate);
    font-size: var(--fz-lg);
    font-weight: bold;
    margin-bottom: 0.5rem;

    a {
      color: var(--lightest-slate);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .service__desc {
    color: var(--light-slate);
    font-size: var(--fz-md);
    margin-bottom: 1rem;
  }

  .service__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;

    li {
      color: var(--green);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      line-height: 1.75;
      margin-right: 0.5rem;
    }
  }
`;

const ServicesPage = ({ location }) => {
  const services = [
    {
      title: 'Advanced Profiling and Deep Insight Generation',

      description:
        'Unlock the power of comprehensive profiling services tailored specifically to your needs. I offer an array of advanced techniques to delve deep into an individual\'s background, unearthing intriguing connections and generating invaluable insights. From uncovering hidden social media accounts and online aliases to tracing digital footprints and analyzing patterns of behavior, my profiling services provide a holistic understanding of the target individual.',

      tags: [
        'man-hunting',
        'OSINT',
        'digital footprints',
        'behavior analysis',
        'online aliases',
        'hidden connections',
      ],
    },
    {
      title: 'Image Geo-Location Service: Uncover the World Behind Every Image',
      description:
        'Our Image Geo-Location Service enables you to unravel the hidden stories behind any image. By utilizing advanced algorithms and geospatial analysis techniques, we accurately determine the geographical location where an image was captured. Whether it\'s a breathtaking landscape, a crowded cityscape, or an enigmatic structure, we provide you with precise coordinates and contextual information that can be invaluable for investigations, travel planning, or content creation. Discover the world behind every image with our powerful geo-location service.',
      tags: ['geolocation', 'GEOINT', 'IMININT'],
    },
    // Add more example services here
  ];

  const contactDetails = {
    email: 'asharbinkhalil@gmail.com',
    socials: 'https://linktr.ee/asharbinkhalil',
  };

  return (
    <Layout location={location}>
      <Helmet title="Services" />

      <StyledMainContainer>
        <header>
          <h1 className="big-heading">My Services</h1>
          <p className="subtitle">Check out the services I offer.</p>
          <br></br>
        </header>

        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {services.length > 0 &&
              services.map(({ title, description, slug, tags }, i) => (
                <tr key={i}>
                  <td>
                    <h5 className="service__title">
                      <Link to={slug}>{title}</Link>
                    </h5>
                  </td>
                  <td>
                    <p className="service__desc">{description}</p>
                  </td>
                  <td>
                    <ul className="service__tags">
                      {tags.map((tag, i) => (
                        <li key={i}>
                          <Link to={`/services/tags/${kebabCase(tag)}/`} className="inline-link">
                            #{tag}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {/* Contact Details */}
        <div>
          <h2>I am offering these services at very reasonable price, contact me at</h2>
          <p>
            Email: <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
          </p>
          <p>
            Fiverr:{' '}
            <a
              href="https://www.fiverr.com/users/asharbinkhalil"
              target="_blank"
              rel="noopener noreferrer"
            >
              fiverr.com//asharbinkhalil
            </a>
          </p>
        </div>
        {/* End of Contact Details */}
      </StyledMainContainer>
    </Layout>
  );
};

ServicesPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ServicesPage;
